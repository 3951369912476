<template>
  <div>
    <div v-if="!AddVisible && !EditVisible" class="view-wrap">
      <div class="search">
        <el-form :inline="true">
          <el-form-item class="hiddenFormItem">
            <el-input
              style="width: 100%"
              v-model="searcForm.param"
              placeholder="请输入供应商名称/编号/联系人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="供应商分类">
            <el-select
              v-model="searcForm.typeId"
              style="width: 100%"
              filterable
              clearable
              placeholder="请选择供应商分类"
            >
              <el-option
                v-for="(item, Index) in typeList"
                :key="Index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="供应商">
            <el-select
              v-model="searcForm.isArrears"
              style="width: 100%"
              filterable
              clearable
              placeholder="请选择供应商"
            >
              <el-option
                v-for="(item, Index) in arrears"
                :key="Index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="供应商创建日期">
            <el-select
              v-model="searcForm.sort"
              style="width: 100%"
              filterable
              clearable
              placeholder="请选择供应商"
            >
              <el-option
                v-for="(item, Index) in sorts"
                :key="Index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getData"
              ><i class="el-icon-search" /> 查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="button">
        <el-button type="primary" round @click="UpDate">
          <i class="el-icon-plus" /> 新增</el-button
        >
      </div>
      <div class="view">
        <SimpleTable
          :data="tableData"
          :tableFields="tableItem"
          :operation="operationEvent"
          :currentPage="searcForm.pageNum"
          :pageSize="searcForm.pageSize"
          :total="total"
          @sizeChange="changePageSize"
          @currentChange="changeCurrentPage"
        >
          <div slot="operation" slot-scope="row">
            <el-button type="text" @click="WatchData(row.data)">查看</el-button>
            <el-button type="text" @click="EditData(row.data)">修改</el-button>
            <el-button type="text" @click="DeleteData(row.data)"
              >删除</el-button
            >
            <!-- <el-tooltip
            class="item"
            effect="dark"
            content="期初设置"
            placement="top-start"
          >
           <el-button type="text" :disabled="row.data.initialPayment !== undefined && row.data.initialAdvancePayment !== undefined" @click="setting(row.data)"
            ><i class="el-icon-setting"></i
          ></el-button>
          </el-tooltip> -->
          </div>
        </SimpleTable>
      </div>
      <miaDialog ref="AddOrEditForm" v-if="Visible">
        <div slot="content">
          <el-form
            ref="form"
            style="width: 70%; margin: 0 auto"
            :model="submitForm"
            :rules="submitRules"
            label-width="120px"
          >
            <el-form-item
              label="期初应付款"
              prop="initialPayment"
              key="initialPayment"
            >
              <el-input-number
                size="mini"
                v-model="submitForm.initialPayment"
                controls-position="right"
                :min="0.0"
                :max="9999999.99"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              label="期初预付款"
              prop="initialAdvancePayment"
              key="initialAdvancePayment"
            >
              <el-input-number
                size="mini"
                v-model="submitForm.initialAdvancePayment"
                controls-position="right"
                :min="0.0"
                :max="9999999.99"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <div style="width: 100%; text-align: center; margin-top: 50px">
              <el-button type="primary" @click="submitData">提交</el-button>
              <el-button @click="close">取消</el-button>
            </div>
          </el-form>
        </div>
      </miaDialog>
    </div>
    <AddSupplier
      ref="AddSupplier"
      v-if="AddVisible"
      @back="backList"
      @refresh="refresh"
    ></AddSupplier>
    <EditSupplier
      ref="EditSupplier"
      v-if="EditVisible"
      @back="backList"
      @refresh="refresh"
    ></EditSupplier>
  </div>
</template>
<script>
import {
  GetSupplierTypeList,
  GetSupplierList,
  DeleteSupplier,
  SettingInitial,
} from "@/api/supplier";
import AddSupplier from "./supplierForm.vue";
import EditSupplier from "./EditSupplier.vue";
export default {
  components: { AddSupplier, EditSupplier },
  data() {
    const validateValue = (rule, value, callback) => {
      const reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(
            new Error("请输入正确范围格式：[0.00-9999999.99]及小数点后两位数字")
          );
        }
      } else {
        callback(new Error("请输入"));
      }
    };
    return {
      typeList: [],
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        typeId: "",
        param: "",
        isArrears: 0,
        sort: 0,
      },
      UpdateType: null,
      tableData: [],
      total: 0,
      tableItem: [
        { label: "操作", type: "operation" },
        { prop: "code", label: "供应商编号", type: "default" },
        { prop: "name", label: "供应商名称", type: "default" },
        { prop: "typeName", label: "供应商分类", type: "default" },
        { prop: "payment", label: "应付欠款(元)", type: "price" },
        { prop: "contact", label: "联系人", type: "default" },
        { prop: "phone", label: "手机号码", type: "default" },
        // { prop: "initialPayment", label: "期初应付款", type: "price" },
        // { prop: "initialAdvancePayment", label: "期初预付款", type: "price" },
      ],
      operationEvent: [
        { type: "edit", event: this.EditData },
        { type: "delete", event: this.DeleteData },
        { type: "setting", event: this.setting },
      ],
      arrears: [
        {
          id: 0,
          name: "全部",
        },

        {
          id: 1,
          name: "欠款供应商",
        },
        {
          id: 2,
          name: "未欠款供应商",
        },
      ],
      sorts: [
        {
          id: 0,
          name: "由近到远",
        },

        {
          id: 1,
          name: "由远到近",
        },
      ],
      Visible: false,
      AddVisible: false,
      EditVisible: false,
      submitForm: {
        supplierId: "",
        initialPayment: "",
        initialAdvancePayment: "",
      },
      submitRules: {
        initialPayment: [
          { required: true, message: "请输入期初应付款", trigger: "blur" },
        ],
        initialAdvancePayment: [
          { required: true, trigger: "blur", message: "请输入期初预付款" },
        ],
      },
    };
  },
  activated() {
    this.GetSupplierType();
  },
  methods: {
    // 获取供应商类别
    GetSupplierType() {
      GetSupplierTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          // 默认选中
          this.getData();
        } else {
          this.typeList = [];
          this.$message.error("未获取到供应商类别");
        }
      });
    },
    getData() {
      GetSupplierList(this.searcForm).then((res) => {
        if (res.code === 1) {
          const { records, current, size, total } = res.data;
          this.tableData = records.map((v) => {
            this.typeList.forEach((t) => {
              if (v.typeId == t.id) {
                v.typeName = t.name;
              }
            });
            return v;
          });
          this.total = total;
          this.searcForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.searcForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.searcForm.pageNum = current - 1;
              this.getData();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 新增
    UpDate() {
      // this.$router.push({ path: "/data/supplierForm" });
      this.AddVisible = true;
    },
    // 修改
    EditData(data) {
      this.EditVisible = true;
      this.$nextTick(() => {
        this.$refs.EditSupplier.init(data.id);
      });
      // this.$router.push({ path: "/data/EditSupplier", query: { id: data.id } });
    },
    WatchData(data) {
      this.EditVisible = true;
      this.$nextTick(() => {
        this.$refs.EditSupplier.init(data.id, "watch");
      });
      // this.$router.push({ path: "/data/EditSupplier", query: { id: data.id } });
    },
    DeleteData(data) {
      this.$confirm(`是否删除该供应商？`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        DeleteSupplier(data.id).then((res) => {
          const { code, msg } = res;
          if (code === 1) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
          this.getData();
        });
      });
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getData();
    },
    // 供应商期初设置
    setting(data) {
      this.submitForm.supplierId = data.id;
      this.submitForm.initialPayment = undefined;
      this.submitForm.initialAdvancePayment = undefined;
      this.Visible = true;
      this.$nextTick(() => {
        this.$refs.AddOrEditForm.init("供应商期初设置");
      });
    },
    // 供应商期初设置提交
    submitData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitForm.initialPayment = +this.submitForm.initialPayment;
          this.submitForm.initialAdvancePayment =
            +this.submitForm.initialAdvancePayment;
          SettingInitial(this.submitForm).then((res) => {
            const { code, msg } = res;
            if (code === 1) {
              this.$message.success(msg);
              this.close();
              this.getData();
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    backList() {
      this.AddVisible = false;
      this.EditVisible = false;
    },
    refresh() {
      this.getData();
    },
    close() {
      this.$refs.AddOrEditForm.close();
    },
  },
};
</script>
