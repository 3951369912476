import request from '@/until/request.js'
const prefixName = '/gm/webSup'
const otherName = '/gm/webSupType'

// 获取供应商列表
export function GetSupplierList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 获取供应商列表
export function searchSupplierList(data){
  return request({
    url: prefixName + '/param',
    method: 'get',
    params: { ...data }
  })
}
// 获取供应商详情
export function GetSupplierDetail(data){
  return request({
    url: prefixName + "/details",
    method: 'get',
    params: data
  })
}
// 获取供应商详情
export function getCode(data){
  return request({
    url: prefixName + "/getCode",
    method: 'get',
    params: data
  })
}
// 创建供应商
export function AddSupplier(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 修改供应商
export function EditSupplier(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 删除供应商
export function DeleteSupplier(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
// 获取供应商类别
export function GetSupplierTypeList(data){
  return request({
    url: otherName,
    method: 'get',
    params: { ...data }
  })
}
// 创建供应商类别
export function AddSupplierType(result){
  return request({
    url: otherName,
    method: 'post',
    data: result
  })
}
// 修改供应商类别
export function EditSupplierType(result){
    return request({
        url: otherName,
        method: 'PUT',
        data: result
    })
}
// 删除供应商类别
export function DeleteSupplierType(id){
    return request({
        url: otherName + '/' + id,
        method: 'delete'
    })
}
// 供应商期初设置
export function SettingInitial(result){
  return request({
    url: prefixName + '/initial',
    method: 'post',
    data: result
  })
}